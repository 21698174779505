<template>
  <div
    class="pxp-header fixed-top pxp-animate"
    ref="header"
    :style="menuColors"
    style="background-color: var(--menu-background-color)"
    v-show="!isAnythingOnTopVisible"
  >
    <div class="container">
      <div class="row align-items-center">
        <div class="col-5 col-md-3 col-lg-2">
          <nuxt-link to="/" :external="true" class="pxp-logo text-decoration-none">
            <img
              :src="company.logo"
              loading="lazy"
              :alt="`${company.name} Logo`"
              style="width: auto; height: var(--logo-height)"
            />
          </nuxt-link>
        </div>
        <div class="col-2 col-md-7 col-lg-8 text-center">
          <ul class="pxp-nav list-inline" style="background-color: var(--menu-background-color-mobile)">
            <li
              v-for="(menuItem, groupIndex) in headerMenuItems"
              :key="`menuItemIndex-${groupIndex}`"
              class="list-inline-item"
            >
              <nuxt-link
                class="theme3-menu-link"
                :external="true"
                :to="menuItem.url"
                :target="menuItem.is_open_new_tab ? '_blank' : ''"
              >
                {{ menuItem.name }}</nuxt-link
              >
              <span
                v-if="menuItem.has_sub_menu"
                class="nc-icon-chevron-down sub-menu-arrow theme3-menu-link"
                @click="toggleSubMenu(groupIndex)"
              ></span>
              <ul
                v-if="menuItem.sub_menus && menuItem.sub_menus.length && menuItem.has_sub_menu"
                class="pxp-nav-sub tw-rounded-sm"
                v-show="menuConfigs && menuConfigs[groupIndex] && menuConfigs[groupIndex].is_toggle_active"
              >
                <li
                  v-for="(subMenu, subMenuIndex) in menuItem.sub_menus"
                  :key="`${subMenuIndex}-subMenu-${groupIndex}`"
                >
                  <nuxt-link
                    class="theme3-sub-menu-link"
                    :external="true"
                    :to="subMenu.url"
                    :target="subMenu.is_open_new_tab ? '_blank' : ''"
                  >
                    {{ subMenu.name }}
                  </nuxt-link>
                </li>
              </ul>
            </li>
            <li class="min-[1200px]:tw-hidden">
              <div class="tw-mt-5 tw-flex">
                <div>
                  <nc-button :is-link="true" link="/contact-us" :external="true" label="CONTACT US" :height="50">
                  </nc-button>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div class="col-5 col-md-2" style="text-align: right">
          <a
            href="javascript:void(0);"
            class="pxp-header-nav-trigger"
            :style="`color: var(--mobile-hamburger-menu-color) !important; border-color: var(--mobile-hamburger-menu-color) !important`"
            ><span class="fa fa-bars"></span
          ></a>

          <div class="tw-hidden min-[1200px]:tw-inline-block">
            <nc-button :is-link="true" link="/contact-us" :external="true" label="CONTACT US" :height="50"> </nc-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { mapState } from 'pinia';
import { ViewTypes } from '~/constants/ui.constants';
import { ComponentMixin } from '~/mixins/component.mixin';
import NcButton from '~/components/common/inputs/NcButton.vue';
import { RepoStates } from '~/units/core/types';
import { useWebsiteStore } from '~/units/website/store';
import { useCoreStore } from '~/units/core/store';
import { CompanyKeys } from '~/units/website/constants';

export default defineNuxtComponent({
  name: 'Theme3BaseHeaderOne',
  components: { NcButton },

  mixins: [ComponentMixin],

  props: {
    component: {
      required: true,
      type: Object,
    },
  },

  data() {
    return {
      ViewTypes,

      menuConfigs: [],
    };
  },

  created() {
    this.menuConfigs = this.headerMenu.map((i) => {
      return {
        is_toggle_active: true,
      };
    });
  },

  mounted() {
    $('.pxp-nav > li').hover(
      function () {
        var subMenu = $(this).children('ul:first');

        if (subMenu.length > 0 && !$('.pxp-header').hasClass('pxp-mobile')) {
          var subMenuWidth = subMenu.width();
          var menuItemLeft = $(this).offset().left;
          var windowWidth = $(window).width();
          var menuItemRight = windowWidth - menuItemLeft;

          if (menuItemRight < subMenuWidth) {
            subMenu.css({
              right: '0',
              left: 'auto',
            });
          }

          subMenu.fadeIn({ queue: false, duration: 200 });
          subMenu.animate({ top: '24px' }, 200);
        }
      },
      function () {
        var subMenu = $(this).children('ul:first');

        if (subMenu.length > 0 && !$('.pxp-header').hasClass('pxp-mobile')) {
          subMenu.fadeOut({ queue: false, duration: 200 });
          subMenu.animate({ top: '10px' }, 200);
        }
      },
    );

    this.updateFirstElementHeight();
    window.addEventListener('resize', this.updateFirstElementHeight);

    window.addEventListener('scroll', this.updateFirstElementHeight);
  },

  beforeUnmount() {
    window.removeEventListener('resize', this.updateFirstElementHeight);
    window.removeEventListener('scroll', this.updateFirstElementHeight);
  },

  computed: {
    ...mapState(useWebsiteStore, ['headerMenuItems', 'companyKey']),
    ...mapState(useCoreStore, ['headerHeight', 'isAnythingOnTopVisible']),

    headerMenu() {
      return this.headerMenuItems;
    },

    routeUrl() {
      const route = useRoute();
      return route.path;
    },

    menuColors() {
      if (this.companyKey === CompanyKeys.BROWNS_ESTATE_AGENTS) {
        return {
          '--menu-item-color-mobile': this.colors?.white,
          '--menu-item-color': this.colors?.white,
          '--menu-item-color__hover': this.colors?.camel,

          '--sub-menu-item-color-mobile': this.colors?.white,
          '--sub-menu-item-color': this.colors?.raisin,

          '--mobile-hamburger-menu-color': this.colors?.white,

          '--menu-background-color': this.colors?.raisin,
          '--menu-background-color-mobile': this.colors?.raisin,

          '--menu-underline-color': this.colors?.camel,
          '--logo-height': '71px',
        };
      } else {
        return {
          '--menu-item-color-mobile': '#808080',
          '--menu-item-color': '#808080',
          '--menu-item-color__hover': this.colors?.camel,

          '--sub-menu-item-color-mobile': '#808080',
          '--sub-menu-item-color': '#808080',

          '--mobile-hamburger-menu-color': '#808080',

          '--menu-background-color': this.colors?.raisin,
          '--menu-background-color-mobile': this.colors?.raisin,

          '--menu-underline-color': this.colors?.purple,

          '--logo-height': '52px',
        };
      }
    },
  },

  methods: {
    toggleSubMenu(index: number) {
      this.menuConfigs[index].is_toggle_active = !this.menuConfigs[index].is_toggle_active;
    },

    updateFirstElementHeight() {
      const coreStore = useCoreStore();
      const size = this.$refs?.header?.offsetHeight;
      coreStore.setHeaderHeight(size || 0);
    },
  },
});
</script>

<style scoped>
.theme3-menu-link {
  color: var(--menu-item-color) !important;
}

.theme3-sub-menu-link {
  color: var(--sub-menu-item-color) !important;
}

.sub-menu-arrow {
  display: none;
  font-size: 14px;
  padding-left: 5px;
}

.pxp-nav > li > a:after {
  background-color: var(--menu-underline-color);
}

.pxp-nav > li > a:hover {
  color: var(--menu-item-color__hover) !important;
}

.theme3-sub-menu-link:hover ~ .pxp-nav > li > a {
  color: var(--menu-item-color__hover) !important;
}

@media only screen and (max-width: 1201px) {
  .theme3-menu-link {
    color: var(--menu-item-color-mobile) !important;
  }

  .theme3-sub-menu-link {
    color: var(--sub-menu-item-color-mobile) !important;
  }

  .sub-menu-arrow {
    display: inline-block;
  }
}

.pxp-header.pxp-mobile:after {
  content: unset !important;
}

.pxp-is-sticky .pxp-nav > li:hover > a:after,
.pxp-mobile .pxp-nav > li:hover > a:after {
  background-color: var(--menu-underline-color);
}
</style>
